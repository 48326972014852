<!-- Language Dropdown -->
<mat-form-field class="mt-4 truncate w-32 max-w-[300px]">
    <mat-select class="custom-select" [(value)]="activeLang" (selectionChange)="setActiveLang($event.value)">
        <mat-option *ngFor="let lang of availableLangs; trackBy: trackByFn" [value]="lang.id">
            <div class="flex items-center">
                <ng-container
                    *ngTemplateOutlet="
                        flagImage;
                        context: { $implicit: lang.id }
                    "
                ></ng-container>
                <span class="">{{ lang.label }}</span>
            </div>
        </mat-option>
    </mat-select>
    <mat-icon>arrow_drop_down</mat-icon>
</mat-form-field>

<!-- Button
<button mat-icon-button [matMenuTriggerFor]="languages">
    <ng-container
        *ngTemplateOutlet="flagImage; context: { $implicit: activeLang }"
    ></ng-container>
</button>

Language menu
<mat-menu [xPosition]="'before'" #languages="matMenu">
    <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
        <button mat-menu-item (click)="setActiveLang(lang.id)">
            <span class="flex items-center">
                <ng-container
                    *ngTemplateOutlet="
                        flagImage;
                        context: { $implicit: lang.id }
                    "
                ></ng-container>
                <span class="ml-3">{{ lang.label }}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

Flag image template
<ng-template let-lang #flagImage>
    <span class="relative w-6 shadow rounded-sm overflow-hidden">
        <span
            class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"
        ></span>
        <img
            class="w-full"
            [src]="
                'assets/images/flags/' + flagCodes[lang].toUpperCase() + '.svg'
            "
            [alt]="'Flag image for ' + lang"
        />
    </span>
</ng-template> -->
